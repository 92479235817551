<template>
  <b-overlay :show="isLoading">
    <section v-if="order">
      <b-container class="col">
        <b-card class="p-lg-1 mb-1">
          <div
            v-if="this.$route.name == 'track-order'"
            class="d-flex float-right mb-1"
          >
            <b-container
              class="px-0 rounded box-shadow-1 d-flex justify-content-center"
            >
              <span class="rounded-left bg-primary text-white p-1">
                Order ID
              </span>
              <span class="rounded-right bg-blue p-1 font-weight-bolder"
                >{{ order.id }}
              </span>
            </b-container>
          </div>
          <BreadCrumbHeader
            :current-step="currentStep"
            :header-list="bcrumbHeaders"
          />
          <b-row>
            <b-col>
              <!-- MAP -->
              <b-container class="border rounded p-2">
                <Map :order="order" />
              </b-container>
            </b-col>
            <b-col>
              <!-- TRACKING SUMMARY -->
              <b-container class="border rounded p-2" style="height: 740px">
                <b class="text-primary font-medium-2 text-uppercase">
                  Tracking Summary
                </b>
                <app-timeline class="mt-3">
                  <app-timeline-item
                    v-for="(activities, index) in order.events"
                    :key="index"
                    :title="activities.message"
                    :variant="activities.color"
                    :subtitle="activities.text"
                    :time="activities.created_at"
                  />
                </app-timeline>
              </b-container>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="d-flex">
              <!-- ORIGIN -->
              <b-container class="border rounded p-2 my-2 flex-fill">
                <b class="text-primary font-medium-2 text-uppercase">
                  Origin
                </b>
                <div class="mt-1">
                  <p>
                    <b> Sender's Name : </b> {{ order.sender.first_name }}
                    {{ order.sender.last_name }}
                  </p>
                  <p>
                    <b> Sender's Address :</b> {{ order.sender.address_unit }},
                    {{ order.sender.address_line_1 }},
                    {{ order.sender.address_line_2 }},
                    {{ order.sender.address_area }},
                    {{ order.sender.address_postcode }},
                    {{ order.sender.address_state }},
                    {{ order.sender.address_country }}
                  </p>
                  <p>
                    <b> Person In Charge :</b> {{ order.sender.first_name }}
                    {{ order.sender.last_name }}
                  </p>
                  <p><b> Phone Number :</b> {{ order.sender.phone }}</p>
                </div>
              </b-container>
            </b-col>
            <b-col class="d-flex">
              <!-- ORDER SUMMARY -->
              <b-container class="border rounded p-2 my-2 flex-fill">
                <b class="text-primary font-medium-2 text-uppercase">
                  Order Summary
                </b>
                <div class="mt-1">
                  <b-row>
                    <b-col
                      ><p><b>Order ID</b>: {{ order.id }}</p>
                      <p>
                        <b>User ID</b>:
                        {{
                          order.order_type == 'Outlet'
                            ? order.user.branch_id
                            : order.user.id
                        }}
                      </p>
                      <p>
                        <b>Delivery ID</b>:
                        <b v-if="order.route_id"> {{ order.route_id }}</b
                        ><span v-else>No Delivery ID</span>
                      </p>
                      <div class="mb-1 d-flex align-items-center">
                        <p class="mb-0 mr-1"><b>Status</b> :</p>
                        <b-button
                          v-if="order.status === 'Active'"
                          variant="success"
                          size="sm"
                          pill
                        >
                          {{ order.status }}
                        </b-button>
                        <b-button
                          v-else-if="order.status === 'Assigned'"
                          variant="primary"
                          size="sm"
                          pill
                        >
                          {{ order.status }}
                        </b-button>
                        <b-button
                          v-else-if="order.status === 'In Warehouse'"
                          variant="info"
                          size="sm"
                          pill
                        >
                          {{ order.status }}
                        </b-button>
                        <b-button
                          v-else-if="order.status === 'In Transit'"
                          variant="warning"
                          size="sm"
                          pill
                        >
                          {{ order.status }}
                        </b-button>
                        <b-button
                          v-else-if="order.status === 'Out For Delivery'"
                          variant="primary"
                          size="sm"
                          pill
                        >
                          {{ order.status }}
                        </b-button>
                        <b-button
                          v-else-if="order.status === 'Delivered'"
                          variant="success"
                          size="sm"
                          pill
                        >
                          {{ order.status }}
                        </b-button>
                        <b-button
                          v-else-if="order.status === 'Second Delivery'"
                          variant="danger"
                          size="sm"
                          pill
                        >
                          {{ order.status }}
                        </b-button>
                        <b-button v-else variant="danger" size="sm" pill>
                          {{ order.status }}
                        </b-button>
                      </div></b-col
                    >
                    <b-col
                      ><div class="mb-1 d-flex align-items-center">
                        <p class="mb-0 mr-1">
                          <b>Shipment:</b>
                        </p>
                        <div v-if="order.order_type">
                          {{
                            order.order_type == 'Outlet'
                              ? 'Outlet'
                              : order.order_type
                          }}
                        </div>
                        <span v-else> No Type of Shipment </span>
                      </div>
                      <p>
                        <b>Order Placed Date : </b>
                        {{ $helpers.formatDate(order.order_placed_date) }}
                      </p>
                      <p>
                        <b>Delivery Date : </b
                        >{{ $helpers.formatDate(order.delivery_date) }}
                      </p>
                      <p>
                        <b>Total Spent : </b
                        >{{ $helpers.formatTotal(order.amount) }}
                      </p></b-col
                    >
                  </b-row>
                </div>
              </b-container>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="d-flex">
              <!-- DESTINATION -->
              <b-container class="border rounded p-2 mb-2 flex-fill">
                <b class="text-primary font-medium-2 text-uppercase">
                  Destination
                </b>
                <div class="mt-1">
                  <p>
                    <b>Recipient's Name : </b>{{ order.recipient.first_name }}
                    {{ order.recipient.last_name }}
                  </p>
                  <p>
                    <b> Recipient's Address : </b>
                    {{ order.recipient.address_unit }},
                    {{ order.recipient.address_line_1 }},
                    {{ order.recipient.address_line_2 }},
                    {{ order.recipient.address_area }},
                    {{ order.recipient.address_postcode }},
                    {{ order.recipient.address_state }},
                    {{ order.recipient.address_country }}
                  </p>
                  <p>
                    <b>Person In Charge : </b>{{ order.recipient.first_name }}
                    {{ order.recipient.last_name }}
                  </p>
                  <p><b> Phone Number: </b> {{ order.recipient.phone }}</p>
                </div>
              </b-container></b-col
            >
            <b-col class="d-flex">
              <!-- PRODUCT DETAILS -->
              <b-container class="border rounded p-2 mb-2 flex-fill">
                <b class="text-primary font-medium-2 text-uppercase">
                  Product Details
                </b>
                <div class="mt-1">
                  <p><b> Quantity :</b> {{ order.quantity }}</p>
                  <p><b> Remarks : </b> {{ order.remarks }}</p>
                  <div class="mb-1 d-flex align-items-center">
                    <p class="mb-0 mr-1">
                      <b>Documents:</b>
                    </p>
                    <b-button
                      class="rounded font-small-2 text-white text-center"
                      variant="primary"
                      @click="generateLabel"
                    >
                      <b class="text-uppercase"> Reprint Label </b>
                    </b-button>
                  </div>
                  <div class="mb-1 d-flex w-100">
                    <p class="mb-0 mr-1">
                      <b>Barcode:</b>
                    </p>
                    <b-img
                      fluid
                      :src="`${$baseURL}/barcode/${order.id}`"
                      alt="Barcode"
                      class="barcode"
                    />
                  </div>
                </div> </b-container
            ></b-col>
          </b-row>
          <div class="border rounded p-2">
            <b-container>
              <b class="text-primary font-medium-2 text-uppercase my-5">
                Proof of Delivery
              </b>
              <b-row class="mt-2">
                <b-col>
                  <div>
                    <h3 class="font-medium-1">Pick Up Photo</h3>
                    <div
                      class="mt-1 p- rounded box-shadow-1 d-flex justify-content-center align-items-center fixed-width-container"
                    >
                      <b-img
                        v-if="order.pick_up_photo"
                        :src="baseURL + order.pick_up_photo"
                        rounded
                        height="220"
                        class="w-auto"
                      />
                      <span v-else>No Photos Uploaded</span>
                    </div>
                  </div></b-col
                >
                <b-col>
                  <div>
                    <h3 class="font-medium-1">Drop Off Photo</h3>
                    <div
                      class="mt-1 mx-0 p-1 rounded box-shadow-1 d-flex justify-content-center align-items-center fixed-width-container"
                    >
                      <b-img
                        v-if="order.drop_off_photo"
                        :src="baseURL + order.drop_off_photo"
                        rounded
                        height="220"
                        class="w-auto"
                      />
                      <span v-else>No Photos Uploaded</span>
                    </div>
                  </div></b-col
                >
              </b-row>
              <div class="row mt-2" />
            </b-container>
          </div>
        </b-card>
        <div class="d-flex justify-content-center align-items-center mt-5">
          <b-button
            v-if="this.$route.name == 'track-order'"
            variant="gradient-primary"
            class="box-shadow-1 px-5 mx-2"
            @click="back"
          >
            Back
          </b-button>
        </div>
      </b-container>
    </section>

    <section v-else>
      <div>
        <h2>-- No tracking found with the given Delivery ID/Order ID --</h2>
        <div class="d-flex justify-content-center align-items-center mt-5">
          <b-button
            v-if="this.$route.name == 'track-order'"
            variant="gradient-primary"
            class="box-shadow-1 px-5 mx-2"
            @click="back"
          >
            Back
          </b-button>
        </div>
      </div>
    </section>
    <b-modal ref="generateLabel" hide-footer hide-title centered size="lg">
      <OrderLabel :order="order" />
    </b-modal>
  </b-overlay>
</template>

<script>
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue';
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue';
import { ORDER_STATUS } from '@/common/Constants';
import OrderLabel from '../MyTask/OrderLabel.vue';
import BreadCrumbHeader from '../../../components/BreadCrumbHeader.vue';
import Map from './Components/Map.vue';

export default {
  name: 'TrackOrderId',
  components: {
    AppTimeline,
    AppTimelineItem,
    BreadCrumbHeader,
    OrderLabel,
    Map,
  },
  props: {
    propOrder: {
      type: [Object, Array],
      default: null,
    },
    propIsLoading: {
      type: Boolean,
      default: null,
    },
  },
  data() {
    return {
      orderLabel: {},
      ORDER_STATUS,
      baseURL: `${this.$s3URL}/minidc/delivery_photo/`,
      isEmpty: null,
      UserData: '',
      order: this.propOrder ? this.propOrder : null,
      isLoading: this.propIsLoading ? this.propIsLoading : false,
      bcrumbHeaders: [
        {
          label: 'Order Placed',
          icon: 'ClipboardIcon',
        },
        {
          label: 'Processing',
          icon: 'RotateCwIcon',
        },
        {
          label: 'In Transit',
          icon: 'BoxIcon',
        },
        {
          label: 'Out for Delivery',
          icon: 'TruckIcon',
        },
        {
          label: 'Delivered',
          icon: 'CheckCircleIcon',
        },
      ],
    };
  },
  computed: {
    currentStep() {
      return this.ORDER_STATUS.findIndex((i) => i.label === this.order.status);
    },
  },
  created() {
    if (!this.propOrder) {
      this.getOrder();
    }
  },
  methods: {
    generateLabel() {
      this.$refs.generateLabel.show();
    },
    async getOrder() {
      this.isLoading = true;
      const response = await this.$http.get(
        `get_order/${this.$route.params.id}`
      );
      if (response.data.status === false) {
        this.isLoading = false;
        this.$swal({
          title: 'Error',
          text: response.data.message,
          icon: 'error',
          showCancelButton: false,
          confirmButtonText: 'Go back',
          customClass: {
            confirmButton: 'btn-danger',
          },
        }).then((result) => {
          if (result.isConfirmed) {
            this.$router.go(-1);
          }
        });
      }

      this.order = response.data.data;
      this.isLoading = false;
    },

    back() {
      this.$router.replace('/track-and-trace');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-good-table.scss';
@import 'src/assets/scss/style.scss';

.fixed-width-container {
  height: 430px;
}
.border {
  border-width: 3px !important;
}
</style>
