const STATES = [
  { label: 'Perlis', value: 'Perlis' },
  { label: 'Kedah', value: 'Kedah' },
  { label: 'Pulau Pinang', value: 'Pulau Pinang' },
  { label: 'Perak', value: 'Perak' },
  { label: 'Pahang', value: 'Pahang' },
  { label: 'Selangor', value: 'Selangor' },
  { label: 'Kuala Lumpur', value: 'Kuala Lumpur' },
  { label: 'Negeri Sembilan', value: 'Negeri Sembilan' },
  { label: 'Melaka', value: 'Melaka' },
  { label: 'Johor', value: 'Johor' },
  { label: 'Terengganu', value: 'Terengganu' },
  { label: 'Kelantan', value: 'Kelantan' },
  { label: 'Sabah', value: 'Sabah' },
  { label: 'Sarawak', value: 'Sarawak' },
  { label: 'Singapore', value: 'Singapore' },
];

const SHIPMENT_TYPE = [
  { label: 'Outlet', value: 'Outlet' },
  // { label: 'B2BPRO', value: 'B2BPRO' },
  // { label: 'B2C (Distributor Order)', value: 'B2C (Distributor Order)' },
  { label: 'Singapore', value: 'Singapore' },
  { label: 'Distributor', value: 'Distributor' },
];

const USER_CATEGORY = [
  { label: 'Gold', value: 'Gold' },
  { label: 'Silver', value: 'Silver' },
  { label: 'Platinum', value: 'Platinum' },
];

const ORDER_STATUS = [
  { label: 'Active' },
  { label: 'Assigned' },
  { label: 'Generated' },
  { label: 'In Warehouse' },
  { label: 'In Transit' },
  { label: 'Arrived At Branch' },
  { label: 'Assigned For Last Mile' },
  { label: 'Out For Delivery' },
  { label: 'Delivered' },
  { label: 'Close Store' },
  { label: 'Cancelled' },
];
const MAP_STYLING = [
  {
    elementType: 'geometry',
    stylers: [
      {
        color: '#f5f5f5',
      },
    ],
  },
  {
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#616161',
      },
    ],
  },
  {
    elementType: 'labels.text.stroke',
    stylers: [
      {
        color: '#f5f5f5',
      },
    ],
  },
  {
    featureType: 'administrative.land_parcel',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#bdbdbd',
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'geometry',
    stylers: [
      {
        color: '#eeeeee',
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#757575',
      },
    ],
  },
  {
    featureType: 'poi.park',
    elementType: 'geometry',
    stylers: [
      {
        color: '#e5e5e5',
      },
    ],
  },
  {
    featureType: 'poi.park',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#9e9e9e',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'geometry',
    stylers: [
      {
        color: '#ffffff',
      },
    ],
  },
  {
    featureType: 'road.arterial',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#757575',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry',
    stylers: [
      {
        color: '#dadada',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#616161',
      },
    ],
  },
  {
    featureType: 'road.local',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#9e9e9e',
      },
    ],
  },
  {
    featureType: 'transit.line',
    elementType: 'geometry',
    stylers: [
      {
        color: '#e5e5e5',
      },
    ],
  },
  {
    featureType: 'transit.station',
    elementType: 'geometry',
    stylers: [
      {
        color: '#eeeeee',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'geometry',
    stylers: [
      {
        color: '#c9c9c9',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#9e9e9e',
      },
    ],
  },
];
export { STATES, SHIPMENT_TYPE, USER_CATEGORY, ORDER_STATUS, MAP_STYLING };
