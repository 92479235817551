<template>
  <div>
    <div ref="map" class="map" />
  </div>
</template>

<script>
export default {
  props: {
    order: Object,
  },
  data() {
    return {
      foundEvent: {},
    };
  },
  mounted() {
    if (this.order.order_type == 'Distributor') {
      for (let i = 0; i < this.order.events.length; i++) {
        const event = this.order.events[i];
        if (event.lat !== null && event.lng !== null) {
          this.foundEvent = event;
          break;
        }
      }
      this.order.sender.lat = this.foundEvent.lat;
      this.order.sender.lng = this.foundEvent.lng;
      this.initMap();
    } else {
      this.order.sender.lat = this.order.pickup.lat;
      this.order.sender.lng = this.order.pickup.lng;
      this.initMap();
    }
  },
  methods: {
    initMap() {
      if (
        this.order.events[this.order.events.length - 1].lat == null &&
        this.order.events[this.order.events.length - 1].lng == null
      ) {
        this.map = new google.maps.Map(this.$refs.map, {
          center: this.startLatLng,
          zoom: 10,
        });
        const overlay = new google.maps.OverlayView();
        overlay.onAdd = function () {
          const div = document.createElement('div');
          div.style.position = 'absolute';
          div.style.background = 'rgba(255, 255, 255, 0.9)';
          div.style.padding = '10px';
          div.style.borderRadius = '5px';
          div.style.display = 'flex';
          div.style.justifyContent = 'center';
          div.style.alignItems = 'center';
          div.textContent = 'No available map tracking';
          this.getPanes().floatPane.appendChild(div);
          this.div = div;
        };

        overlay.draw = function () {
          const overlayProjection = this.getProjection();
          const sw = overlayProjection.fromLatLngToDivPixel(
            this.map.getBounds().getSouthWest()
          );
          const ne = overlayProjection.fromLatLngToDivPixel(
            this.map.getBounds().getNorthEast()
          );
          this.div.style.width = `${ne.x - sw.x}px`;
          this.div.style.height = `${sw.y - ne.y}px`;
          this.div.style.left = `${sw.x}px`;
          this.div.style.top = `${ne.y}px`;
        };

        overlay.setMap(this.map);
      } else {
        this.map = new google.maps.Map(this.$refs.map, {
          center: { lat: this.order.sender.lat, lng: this.order.sender.lng },
          zoom: 12,
        });
        const directionsService = new google.maps.DirectionsService();
        const directionsRenderer = new google.maps.DirectionsRenderer({
          map: this.map,
          suppressMarkers: true,
        });
        const start = new google.maps.LatLng(
          this.order.sender.lat,
          this.order.sender.lng
        );
        const end = new google.maps.LatLng(
          this.order.events[this.order.events.length - 1].lat,
          this.order.events[this.order.events.length - 1].lng
        );
        directionsService.route(
          {
            origin: start,
            destination: end,
            travelMode: 'DRIVING',
          },
          (response, status) => {
            if (status === 'OK') {
              directionsRenderer.setDirections(response);
              const customMarker = new google.maps.Marker({
                position: {
                  lat: this.order.events[this.order.events.length - 1].lat,
                  lng: this.order.events[this.order.events.length - 1].lng,
                },
                map: this.map,
                icon: {
                  url: require('@/assets/images/logo/truck.png'),
                  scaledSize: new google.maps.Size(100, 70),
                  anchor: new google.maps.Point(50, 35),
                },
              });
              const startInfoContent = `<strong>${
                this.order.shipment
                  ? this.order.shipment
                  : this.order.order_type
              }</strong><br><br>
                                      Address: ${
                                        this.order.order_type == 'Distributor'
                                          ? this.order.sender.complete_address
                                          : this.order.pickup.address
                                      }`;
              const eventInfoContent = `
                                      Message: ${
                                        this.order.events[
                                          this.order.events.length - 1
                                        ].message
                                      }`;
              const endInfoContent = `<strong>Recipient</strong><br><br>
                                    Name: ${
                                      this.order.recipient.last_name
                                        ? `${this.order.recipient.first_name} ${this.order.recipient.last_name}`
                                        : this.order.recipient.first_name
                                    }<br>
                                    Phone: ${this.order.recipient.phone}<br>
                                    Address: ${
                                      this.order.recipient.complete_address
                                    }<br>`;
              const startMarker = new google.maps.Marker({
                position: {
                  lat: this.order.sender.lat,
                  lng: this.order.sender.lng,
                },
                map: this.map,
              });
              this.addHoverInfoWindow(startMarker, startInfoContent);
              const endMarker = new google.maps.Marker({
                position: {
                  lat: this.order.events[this.order.events.length - 1].lat,
                  lng: this.order.events[this.order.events.length - 1].lng,
                },
                map: this.map,
              });
              if (
                this.order.events[this.order.events.length - 1].message !=
                'Delivered'
              ) {
                this.addHoverInfoWindow(endMarker, eventInfoContent);
              } else {
                this.addHoverInfoWindow(endMarker, endInfoContent);
              }
            } else {
              window.alert(`Directions request failed due to ${status}`);
            }
          }
        );
      }
    },
    addHoverInfoWindow(marker, content) {
      const infoWindow = new google.maps.InfoWindow({
        content,
      });
      marker.addListener('mouseover', () => {
        infoWindow.open(this.map, marker);
      });
      marker.addListener('mouseout', () => {
        infoWindow.close();
      });
    },
  },
};
</script>

<style scoped>
.map {
  width: 100%;
  height: 692px;
}
</style>
