<template>
  <b-container ref="orderLabel" class="d-flex flex-column px-0 px-md-1 px-lg-1">
    <!-- Label Header -->
    <div class="d-flex justify-content-between">
      <div class="d-flex justify-content-center align-items-center">
        <b-card no-body class="mr-1 mt-1 p-1">
          <b-img fluid :src="imgUrl" alt="Image" class="logo" />
        </b-card>
        <span class="d-none d-lg-inline h1 font-large-1">
          {{ order.order_type === 'Outlet' ? 'Outlet' : order.order_type }}
          <br />
          Delivery Order
        </span>
      </div>
      <div class="d-flex align-items-center">
        <b-img
          fluid
          :src="`${$baseURL}/barcode/${order.id}`"
          alt="Image"
          class="logo"
        />
      </div>
    </div>

    <!-- First Table -->
    <b-container class="px-0 table-responsive">
      <table class="table table-bordered">
        <tbody>
          <tr>
            <th class="font-medium-1">Order ID</th>
            <td>{{ order.id ? order.id : '' }}</td>
            <th class="font-medium-1">
              {{ order.order_type === 'Outlet' ? 'Outlet' : order.order_type }}
              Name
            </th>
            <td>{{ order.pickup ? order.pickup.area : '' }}</td>
          </tr>
          <tr>
            <th class="font-medium-1">Order Type</th>
            <td>
              {{ order.order_type === 'Outlet' ? 'Outlet' : order.order_type }}
            </td>
            <th v-if="order.delivery_date" class="font-medium-1">
              Delivery Date
            </th>
            <th v-else class="font-medium-1">Pick Up Date</th>
            <td v-if="order.delivery_date">
              {{
                order.delivery_date.substr(0, 10)
                  ? order.delivery_date.substr(0, 10)
                  : ''
              }}
            </td>
            <td v-else>
              {{
                order.pickup_date.substr(0, 10)
                  ? order.pickup_date.substr(0, 10)
                  : ''
              }}
            </td>
          </tr>
        </tbody>
      </table>
    </b-container>

    <!-- Second Table -->
    <b-container class="px-0 table-responsive">
      <table class="table table-bordered">
        <tbody class="text-center">
          <tr>
            <th class="font-medium-1">Origin</th>
            <th class="font-medium-1">Destination</th>
          </tr>
          <tr>
            <td>
              {{ order.sender.first_name ? order.sender.first_name : ' ' }}
              {{ order.sender.last_name ? order.sender.last_name : '' }}
            </td>
            <td>
              {{
                order.recipient.first_name ? order.recipient.first_name : ' '
              }}
              {{ order.recipient.last_name ? order.recipient.last_name : '' }}
            </td>
          </tr>
          <tr>
            <td>
              {{ order.sender.address_unit ? order.sender.address_unit : '' }},
              {{
                order.sender.address_line_1 ? order.sender.address_line_1 : ''
              }},
              {{
                order.sender.address_line_2 ? order.sender.address_line_2 : ''
              }},
              {{
                order.sender.address_postcode
                  ? order.sender.address_postcode
                  : ''
              }},
              {{ order.sender.address_area ? order.sender.address_area : '' }},
              {{
                order.sender.address_state ? order.sender.address_state : ''
              }},
              {{
                order.sender.address_country ? order.sender.address_country : ''
              }}
            </td>
            <td>
              {{
                order.recipient.address_unit
                  ? order.recipient.address_unit
                  : ''
              }},
              {{
                order.recipient.address_line_1
                  ? order.recipient.address_line_1
                  : ''
              }},
              {{
                order.recipient.address_line_2
                  ? order.recipient.address_line_2
                  : ''
              }},
              {{
                order.recipient.address_postcode
                  ? order.recipient.address_postcode
                  : ''
              }},
              {{
                order.recipient.address_area
                  ? order.recipient.address_area
                  : ''
              }},
              {{
                order.recipient.address_state
                  ? order.recipient.address_state
                  : ''
              }},
              {{
                order.recipient.address_country
                  ? order.recipient.address_country
                  : ''
              }}
            </td>
          </tr>
          <tr>
            <td>{{ order.sender.phone ? order.sender.phone : '' }}</td>
            <td>{{ order.recipient.phone ? order.recipient.phone : '' }}</td>
          </tr>
        </tbody>
      </table>
    </b-container>

    <!-- Third Table -->
    <b-container class="px-0 table-responsive">
      <table class="table table-bordered">
        <tbody class="text-center">
          <tr>
            <th class="font-medium-1">Quantity</th>
            <th class="font-medium-1">Weight (kg)</th>
            <th class="font-medium-1">Size</th>
          </tr>
          <tr>
            <td>{{ order.quantity ? order.quantity : '' }}</td>
            <td>{{ order.item_weight ? order.item_weight : '' }}</td>
            <td>{{ order.item_size ? order.item_size : '' }}</td>
          </tr>
          <tr>
            <th class="font-medium-1">Temperature</th>
            <th class="font-medium-1">Product Category</th>
            <th class="font-medium-1">Packaging</th>
          </tr>
          <tr>
            <td>{{ order.temperature ? order.temperature : '' }}</td>
            <td>{{ order.product_category ? order.product_category : '' }}</td>
            <td>{{ order.packaging ? order.packaging : '' }}</td>
          </tr>
          <tr>
            <th class="font-medium-1">SKU</th>
            <th colspan="2" class="font-medium-1">Remarks</th>
          </tr>
          <tr>
            <td>{{ order.sku ? order.sku : '' }}</td>
            <td colspan="2">
              {{ order.remarks }}
            </td>
          </tr>
        </tbody>
      </table>
    </b-container>
  </b-container>
</template>

<script>
import store from '@/store/index';

export default {
  props: { order: {} },

  data() {
    return {
      sideImg: require('@/assets/images/logo/logo-red.png'),
    };
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        this.sideImg = require('@/assets/images/logo/logo-white.png');
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
  mounted() {
    window.open(
      `${this.$baseURL}/generate_golog_label/${this.order.id}`,
      '_blank'
    );
  },
};
</script>

<style scoped>
.logo {
  height: 60px;
  width: auto;
}
</style>
